import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Homey/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Using MQTT to inform Homey that an Alarm was Triggered",
  "path": "/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Homey/",
  "dateChanged": "2020-04-07",
  "author": "Mike Polinowski",
  "excerpt": "I found your guide to use the INSTAR (HTTP) Alarmserver. Wouldn't it make more sense to publish those events through the MQTT service that every Full HD cameras offers? This way I would be able to be sure that every alarm event is registered by my Homey.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras",
  "category": "smarthome",
  "type": "Homey"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='FAQs - Using MQTT to inform Homey that an Alarm was Triggered' dateChanged='2020-04-07' author='Mike Polinowski' tag='INSTAR IP Camera' description='I found your guide to use the INSTAR (HTTP) Alarmserver. Wouldn`t it make more sense to publish those events through the MQTT service that every Full HD cameras offers? This way I would be able to be sure that every alarm event is registered by my Homey.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Homey/' locationFR='/fr/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Homey/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "using-mqtt-to-inform-homey-that-an-alarm-was-triggered",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-mqtt-to-inform-homey-that-an-alarm-was-triggered",
        "aria-label": "using mqtt to inform homey that an alarm was triggered permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using MQTT to inform Homey that an Alarm was Triggered`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I found your `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"
      }}>{`guide to use the INSTAR (HTTP) Alarmserver`}</a>{`. Wouldn't it make more sense to publish those events through the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT service that every Full HD cameras`}</a>{` offers? This way I would be able to be sure that every alarm event is registered by my Homey.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: All alarm events that published by the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`INSTAR HTTP Alarmserver`}</a>{` can now also be accessed through the MQTT Service!`}</p>
    <h2 {...{
      "id": "mqtt-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-alarmserver",
        "aria-label": "mqtt alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Alarmserver`}</h2>
    <p>{`The corresponding `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/"
      }}>{`MQTT Topic`}</a>{` is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/triggered`}</code></pre></div>
    <p>{`All you need to do is adding a subscription to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`Status Topic`}</a>{` and you will receive updates about alarm events as well as what service triggered the alarm:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "setting-up-homey",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-homey",
        "aria-label": "setting up homey permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up Homey`}</h2>
    <p>{`To keep it simple I will ignore the alarm trigger and have my Homey base station's LED ring flash in red whenever an alarm event happened. Let's start by creating a new `}<strong parentName="p">{`Flow`}</strong>{` in the Homey smartphone app:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/5a190/INSTAR_MQTT_Alarmserver_Homey_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAByklEQVQoz2XOS08TcRjF4X90ASiX2FQgaWI00QTjAhYFbOlFU4MxGlcsiW5MjBt3Ai1OIWKHmU7buZTpBaHtzHRaxRhC/IA/wwxGA4sn56ze94iHpTbz6jFzpTYz2xa382Zoy7hghjatwPTGP7OfLG5t2ix/2GP9fZEHO0eIO19sIgWD+1+bxIp1InmdaMEgmr/k/+MXD2Y2TMYLTV693SD/Yp3olo3IHLi8Ozkj1xjw8vAHq80hScMJ6S4rAe+K1Llan3TNI64PyLROWZA7iHTD4c33X2QbfTK2R9JySJgOSdMl+TcNl5WAd0XK6LNSdXndPCFe7iHi1jFr3k+yTY9c2yfX8nls9YgbncCi3mFJ77Jc67IU6IWqoeWqw2KlR9b0eSR/Qzw/8vl49ptn7SGrh0NyrQFp2yN14JK1vXB1wyfR8Mke9HlS75M9Z4X9qeWTNjzWjk9JVF3E9fIeYxWZSV1hpFJiRCsRNTSmjQqjmsykKjNXlJiXJKYUmRtlhcmySqSiMa7uM6EqTKgqN/eVgBC2RMzRmO1qiHoRUZeIdSvcc3REfYfR2mcW9vNkyhJj1W2EtUukpXC3oyOMHa4Zu0zZMpGmEvQ/E9JvD44TNu4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/e4706/INSTAR_MQTT_Alarmserver_Homey_01.avif 230w", "/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/d1af7/INSTAR_MQTT_Alarmserver_Homey_01.avif 460w", "/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/c9875/INSTAR_MQTT_Alarmserver_Homey_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/a0b58/INSTAR_MQTT_Alarmserver_Homey_01.webp 230w", "/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/bc10c/INSTAR_MQTT_Alarmserver_Homey_01.webp 460w", "/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/d00b9/INSTAR_MQTT_Alarmserver_Homey_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/81c8e/INSTAR_MQTT_Alarmserver_Homey_01.png 230w", "/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/08a84/INSTAR_MQTT_Alarmserver_Homey_01.png 460w", "/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/5a190/INSTAR_MQTT_Alarmserver_Homey_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bbc5aa7485effbfd79b4c6aea46d3e9b/5a190/INSTAR_MQTT_Alarmserver_Homey_01.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Add Card`}</strong>{` to add the event you want to trigger your flow:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b8fa781422dd32bb776f75de158fda8/5a190/INSTAR_MQTT_Alarmserver_Homey_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABEUlEQVQY003MTzMCYQDA4b6MMyPJtPu+7w6uHLj4FszkQEnNGFFUsm3+bHwEYzBTOZjBJY3am4N227Z8j58RB4fn+oRK8y7lRRd7pY+96lNZ8DiVHhXlYf2QfSzpU5UDzuSAczngQgZcigBbDKmJEVdixLX4GguZyz3MJZdj9UnB6FFULifKpWj4FIwhZcPHVB6m7FNRfSz1Pw9+cxlgyyE1OSKU1F7ZiNwSjzwQj9yzGbljfbZJedKiNbGGOVVlSzyRFk0S0QbbM3USf5LhBjvhJqnwI8npOrm5NqGMckiJFruiTVq0yehvpPV38rFnatEbjrQX9pRDVnXZ1ztktQ4HWnfsUHPIaQ75H7EuJf2DbwvrztRcIcoHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b8fa781422dd32bb776f75de158fda8/e4706/INSTAR_MQTT_Alarmserver_Homey_02.avif 230w", "/en/static/2b8fa781422dd32bb776f75de158fda8/d1af7/INSTAR_MQTT_Alarmserver_Homey_02.avif 460w", "/en/static/2b8fa781422dd32bb776f75de158fda8/c9875/INSTAR_MQTT_Alarmserver_Homey_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b8fa781422dd32bb776f75de158fda8/a0b58/INSTAR_MQTT_Alarmserver_Homey_02.webp 230w", "/en/static/2b8fa781422dd32bb776f75de158fda8/bc10c/INSTAR_MQTT_Alarmserver_Homey_02.webp 460w", "/en/static/2b8fa781422dd32bb776f75de158fda8/d00b9/INSTAR_MQTT_Alarmserver_Homey_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b8fa781422dd32bb776f75de158fda8/81c8e/INSTAR_MQTT_Alarmserver_Homey_02.png 230w", "/en/static/2b8fa781422dd32bb776f75de158fda8/08a84/INSTAR_MQTT_Alarmserver_Homey_02.png 460w", "/en/static/2b8fa781422dd32bb776f75de158fda8/5a190/INSTAR_MQTT_Alarmserver_Homey_02.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b8fa781422dd32bb776f75de158fda8/5a190/INSTAR_MQTT_Alarmserver_Homey_02.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This event has to be an MQTT status update from our camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ff440964581ac85a816b1b364225411c/5a190/INSTAR_MQTT_Alarmserver_Homey_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA1klEQVQoz5WQu2rDMBSG9YCdOmfqq3Tp2qVvU+grJHQoZCjNxVJQbEdKbFmypK8gh9It8YGPA4f/3H7RNA1SSvq+p+s6nHPMjZxz6T2fLSKMkaqSKHUoWesjKWXGGIkx3U3KmZxB2PZEiAkf4pUR5zxuCDPxpU+06y8Ox5rdbousKpRSNHX990pZyw2KZtKLjdmglWYYhqn435uUiuheUkqIpd7TSIW2hmG5wjw8YhdP+PePaWiM8y7cW4s9GdrLhe77B/P8gnl9o199EgDvQ/H1FuGafwFkfx7oVKWqjAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff440964581ac85a816b1b364225411c/e4706/INSTAR_MQTT_Alarmserver_Homey_03.avif 230w", "/en/static/ff440964581ac85a816b1b364225411c/d1af7/INSTAR_MQTT_Alarmserver_Homey_03.avif 460w", "/en/static/ff440964581ac85a816b1b364225411c/c9875/INSTAR_MQTT_Alarmserver_Homey_03.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ff440964581ac85a816b1b364225411c/a0b58/INSTAR_MQTT_Alarmserver_Homey_03.webp 230w", "/en/static/ff440964581ac85a816b1b364225411c/bc10c/INSTAR_MQTT_Alarmserver_Homey_03.webp 460w", "/en/static/ff440964581ac85a816b1b364225411c/d00b9/INSTAR_MQTT_Alarmserver_Homey_03.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff440964581ac85a816b1b364225411c/81c8e/INSTAR_MQTT_Alarmserver_Homey_03.png 230w", "/en/static/ff440964581ac85a816b1b364225411c/08a84/INSTAR_MQTT_Alarmserver_Homey_03.png 460w", "/en/static/ff440964581ac85a816b1b364225411c/5a190/INSTAR_MQTT_Alarmserver_Homey_03.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ff440964581ac85a816b1b364225411c/5a190/INSTAR_MQTT_Alarmserver_Homey_03.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add the MQTT alarm server status topic:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/da36375a87b6cea335312e2c16890109/5a190/INSTAR_MQTT_Alarmserver_Homey_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABZElEQVQoz52SzYoUMRSF6xV9Ahc+gLr0aVyqiDgg+gKu3Dj419OzkXami64Ze6pSqdTNTaqSTyo04qAMtBc+Tgjh5OTeVFEVL8J2u6Wua2KMzPN8FCklpmlCRKiMKkYELx7xypxSIcRIiNNB7+L2mer5eM2b5gd907C7aui6DucG/rcqGyeME7q2xxjLft/SdZZpmsvt8UgqYzrMcIMLFqeWMTicDjgZER+Oplr17zipH/N294ST+hGv64e8uHzAp+5lecKcptL0lDIp59+ac8arMo5jGcaiqkr12b7i6eU9ntX3+S7vkWzo5x0hSzHMh97kg8nCUstv8N4Xk4XFdNmrWnvBxc8PbPcfEduS3MQ8BOIghMERrCO48a9eaQh4DUVVQ0mrIVLJpuZmvaE933C9Pqf++oVmtaJZnXF1tmZzesr+2wpSunO6S/KS0A8OMQbpe3xv8f1w0D8ZSgrVeAv/j/UvxEIBhvA77pMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da36375a87b6cea335312e2c16890109/e4706/INSTAR_MQTT_Alarmserver_Homey_04.avif 230w", "/en/static/da36375a87b6cea335312e2c16890109/d1af7/INSTAR_MQTT_Alarmserver_Homey_04.avif 460w", "/en/static/da36375a87b6cea335312e2c16890109/c9875/INSTAR_MQTT_Alarmserver_Homey_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/da36375a87b6cea335312e2c16890109/a0b58/INSTAR_MQTT_Alarmserver_Homey_04.webp 230w", "/en/static/da36375a87b6cea335312e2c16890109/bc10c/INSTAR_MQTT_Alarmserver_Homey_04.webp 460w", "/en/static/da36375a87b6cea335312e2c16890109/d00b9/INSTAR_MQTT_Alarmserver_Homey_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/da36375a87b6cea335312e2c16890109/81c8e/INSTAR_MQTT_Alarmserver_Homey_04.png 230w", "/en/static/da36375a87b6cea335312e2c16890109/08a84/INSTAR_MQTT_Alarmserver_Homey_04.png 460w", "/en/static/da36375a87b6cea335312e2c16890109/5a190/INSTAR_MQTT_Alarmserver_Homey_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/da36375a87b6cea335312e2c16890109/5a190/INSTAR_MQTT_Alarmserver_Homey_04.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And save the flow trigger:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/20b96ff542adaa9f289510934807cad5/5a190/INSTAR_MQTT_Alarmserver_Homey_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.956521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABQUlEQVQY00XFSU7CUACA4R4KoWL7TDSudKMHcp4gMSgUkEGPoGcwGhx2GlugtOUVWoYYB4YF0cVv7MbFl0/JLDmcrjiU1z0qGx7GsktWuOQWXYxFD0N45EWbgmhTFJJzISnpPmXdpyI6VPUuF3qXy0iAUlxzKK+6pPUWJ8KJZIRLRnc5Ex5Z4ZETbXJ6GyMiyWuSguZTWJAUkpJiUlLWulS0Lkq11OFa9gie3/DrHwycCf3WmKE7je7ZI8LmF317zNCZRAatP2Pe5Yyvzjef/g8fvSm18zeUkiG5qoc0ayHmY8jrfcDLnU/jqU/9sYd5H0SshxCz9v/LrU9gThjaM4aNGX1nxE12gLKvmRyoFpvzJluqybZqsTNvsata7Kl1DtQGh38STQ4TDY4STY4TNqm4TWrOJhWzScdsTuIOp0mXX0WFLoQrhq/SAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20b96ff542adaa9f289510934807cad5/e4706/INSTAR_MQTT_Alarmserver_Homey_05.avif 230w", "/en/static/20b96ff542adaa9f289510934807cad5/d1af7/INSTAR_MQTT_Alarmserver_Homey_05.avif 460w", "/en/static/20b96ff542adaa9f289510934807cad5/c9875/INSTAR_MQTT_Alarmserver_Homey_05.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/20b96ff542adaa9f289510934807cad5/a0b58/INSTAR_MQTT_Alarmserver_Homey_05.webp 230w", "/en/static/20b96ff542adaa9f289510934807cad5/bc10c/INSTAR_MQTT_Alarmserver_Homey_05.webp 460w", "/en/static/20b96ff542adaa9f289510934807cad5/d00b9/INSTAR_MQTT_Alarmserver_Homey_05.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20b96ff542adaa9f289510934807cad5/81c8e/INSTAR_MQTT_Alarmserver_Homey_05.png 230w", "/en/static/20b96ff542adaa9f289510934807cad5/08a84/INSTAR_MQTT_Alarmserver_Homey_05.png 460w", "/en/static/20b96ff542adaa9f289510934807cad5/5a190/INSTAR_MQTT_Alarmserver_Homey_05.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/20b96ff542adaa9f289510934807cad5/5a190/INSTAR_MQTT_Alarmserver_Homey_05.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we have to add an action we want to execute when our flow was triggered by the MQTT Alarmserver:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/98a0332df1162c51555bb2e52cb47dfa/5a190/INSTAR_MQTT_Alarmserver_Homey_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABS0lEQVQoz23Qy0rDQBjF8WxFzKSTpF6qtOBt7U7c+wDiAyiIYkVFqGnS3Io2tirYpEnFuvBZ/5KkraAufpxZnfMxSi3M2Ik+2Xv5otF9Z9lNMZwE0xlhzjOXYtop1Vy7tJynlVKzx2z6Eza9CcpaN2MlTNmOJoXVIEXvJJi/zYun7P8HlEbvnXpvjNp5Q7hDpBeje0nBcKc6pZ/y5Nf1o3m5Uo/G1B5SKv4Q3Y+Rfl46RPdijKK8HDD+GTCm5bMvWvMylHp/zPpThgjekOEQGcboQYwWJCx2U9QgKQYqXj6UTK8f/Rkw3RGrfoYiWiHisoXatAri0mLhymb39IaPg0OOjk9Q7wI2bj1k00a9sBAXbbSZ85yNdmYhrx9QhD9A2D2EEyGcXvFeciKq1iP7zTZbdwFaZ0DVfUbafTTriYrVL92XZJ6tPrL9yjcBHCTgmJVDrAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98a0332df1162c51555bb2e52cb47dfa/e4706/INSTAR_MQTT_Alarmserver_Homey_06.avif 230w", "/en/static/98a0332df1162c51555bb2e52cb47dfa/d1af7/INSTAR_MQTT_Alarmserver_Homey_06.avif 460w", "/en/static/98a0332df1162c51555bb2e52cb47dfa/c9875/INSTAR_MQTT_Alarmserver_Homey_06.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/98a0332df1162c51555bb2e52cb47dfa/a0b58/INSTAR_MQTT_Alarmserver_Homey_06.webp 230w", "/en/static/98a0332df1162c51555bb2e52cb47dfa/bc10c/INSTAR_MQTT_Alarmserver_Homey_06.webp 460w", "/en/static/98a0332df1162c51555bb2e52cb47dfa/d00b9/INSTAR_MQTT_Alarmserver_Homey_06.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98a0332df1162c51555bb2e52cb47dfa/81c8e/INSTAR_MQTT_Alarmserver_Homey_06.png 230w", "/en/static/98a0332df1162c51555bb2e52cb47dfa/08a84/INSTAR_MQTT_Alarmserver_Homey_06.png 460w", "/en/static/98a0332df1162c51555bb2e52cb47dfa/5a190/INSTAR_MQTT_Alarmserver_Homey_06.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/98a0332df1162c51555bb2e52cb47dfa/5a190/INSTAR_MQTT_Alarmserver_Homey_06.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To keep it simple I will just have Homey flash it's LED ring in red:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ec4878fa33f1f43bc77af51e3d45c82/5a190/INSTAR_MQTT_Alarmserver_Homey_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA60lEQVQoz6WRPU7EMBBGfSU67kDFSWg4ExJX4aegoFiKRbGU7CrBwcrGSZyx/ZCTAjqU3ZE+TWHpzbM+ZYyhKAr6/kSMERFh6zjn6LoOay0qA8qyQmtNdThQaI33MyGEBS4S/okQYlxkcpQxLZMXvJd1z8IweoZx2pZhwg0Tyjw903Qds/eklJYreZ+TGBOq371St5Z0AegXGFHHj0/Md4vMaxkXA8v3E61tliLID9n0Alul94a6PhIkrIZ/c47hrn1jHtbvRu+JTUPKBYlshi6luBfNvtJ8lRXu7p726hp7c8v48LgaS9hk+AMRhQzhc4aijwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ec4878fa33f1f43bc77af51e3d45c82/e4706/INSTAR_MQTT_Alarmserver_Homey_07.avif 230w", "/en/static/3ec4878fa33f1f43bc77af51e3d45c82/d1af7/INSTAR_MQTT_Alarmserver_Homey_07.avif 460w", "/en/static/3ec4878fa33f1f43bc77af51e3d45c82/c9875/INSTAR_MQTT_Alarmserver_Homey_07.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ec4878fa33f1f43bc77af51e3d45c82/a0b58/INSTAR_MQTT_Alarmserver_Homey_07.webp 230w", "/en/static/3ec4878fa33f1f43bc77af51e3d45c82/bc10c/INSTAR_MQTT_Alarmserver_Homey_07.webp 460w", "/en/static/3ec4878fa33f1f43bc77af51e3d45c82/d00b9/INSTAR_MQTT_Alarmserver_Homey_07.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ec4878fa33f1f43bc77af51e3d45c82/81c8e/INSTAR_MQTT_Alarmserver_Homey_07.png 230w", "/en/static/3ec4878fa33f1f43bc77af51e3d45c82/08a84/INSTAR_MQTT_Alarmserver_Homey_07.png 460w", "/en/static/3ec4878fa33f1f43bc77af51e3d45c82/5a190/INSTAR_MQTT_Alarmserver_Homey_07.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ec4878fa33f1f43bc77af51e3d45c82/5a190/INSTAR_MQTT_Alarmserver_Homey_07.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose the `}<strong parentName="p">{`Animation`}</strong>{` option:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/929717d1f6f094584fd47756a7c0cb50/5a190/INSTAR_MQTT_Alarmserver_Homey_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAo0lEQVQY053Q7Q7BQBCF4b3/GyMSIhJ+IihbraXd9dGZ3X2luACc5Pl7JmfMNQSccxRFwdV7yJlfo6qEEF7MLQr3rsNay9qWdIB0goh+SRBVNEZijBhZHGn2NSfvkeGIx3SG9ldFUY1f60t7Jg42+F1N1V5oxhN0uXrv+GN6H5OGW5rtEVuVWOe4p/Tpy38xOi+5FBX12WEPlrZpSTl/fpJ+9gTbg9UgivaZBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/929717d1f6f094584fd47756a7c0cb50/e4706/INSTAR_MQTT_Alarmserver_Homey_08.avif 230w", "/en/static/929717d1f6f094584fd47756a7c0cb50/d1af7/INSTAR_MQTT_Alarmserver_Homey_08.avif 460w", "/en/static/929717d1f6f094584fd47756a7c0cb50/c9875/INSTAR_MQTT_Alarmserver_Homey_08.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/929717d1f6f094584fd47756a7c0cb50/a0b58/INSTAR_MQTT_Alarmserver_Homey_08.webp 230w", "/en/static/929717d1f6f094584fd47756a7c0cb50/bc10c/INSTAR_MQTT_Alarmserver_Homey_08.webp 460w", "/en/static/929717d1f6f094584fd47756a7c0cb50/d00b9/INSTAR_MQTT_Alarmserver_Homey_08.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/929717d1f6f094584fd47756a7c0cb50/81c8e/INSTAR_MQTT_Alarmserver_Homey_08.png 230w", "/en/static/929717d1f6f094584fd47756a7c0cb50/08a84/INSTAR_MQTT_Alarmserver_Homey_08.png 460w", "/en/static/929717d1f6f094584fd47756a7c0cb50/5a190/INSTAR_MQTT_Alarmserver_Homey_08.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/929717d1f6f094584fd47756a7c0cb50/5a190/INSTAR_MQTT_Alarmserver_Homey_08.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And select the colour and duration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cbf22e5d4f43e962c7575dd2e91fd388/5a190/INSTAR_MQTT_Alarmserver_Homey_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABSElEQVQ4y6WUwW7DIAyGefae+0R7gh563mmbNimrmlZKShKwMZB/ApJpU7N0W5E+YYxjbMeghn5AXdc4HN5BRPjPiDHCWou+76EoCCwTqqpCfTrBOQcRgRPJ8y2KnYcPAcZYKF9pkDZgcYhxRAgxb/4ZH/K3anx4xunxFU9vLzm6e4fiGMAiIBawEzDfh2qaC9pWZ4eW3N2o8/kMY0yJdxxXkhl/l7KxlD0Tr2C5wO4mquuG7HCxfkmXDgMyzO5mndVq/CGUxt1uETebqYvDespxHEv/xXiND4gA/H4Pv9tlOemu7EJi6sOL7qG7AcbyMoaQflkmyQs2qWROAiwJlNZdvj5zpN+JJXrvEcRPkXwl5P30BhyPNYbBFIfWEojSSZTleT0jk7OlK5f0zgmSH2KGSuk2rUZ76dC0M/O6zP1gfy5Jxn1ejA/bcJUKr3e32wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbf22e5d4f43e962c7575dd2e91fd388/e4706/INSTAR_MQTT_Alarmserver_Homey_09.avif 230w", "/en/static/cbf22e5d4f43e962c7575dd2e91fd388/d1af7/INSTAR_MQTT_Alarmserver_Homey_09.avif 460w", "/en/static/cbf22e5d4f43e962c7575dd2e91fd388/c9875/INSTAR_MQTT_Alarmserver_Homey_09.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cbf22e5d4f43e962c7575dd2e91fd388/a0b58/INSTAR_MQTT_Alarmserver_Homey_09.webp 230w", "/en/static/cbf22e5d4f43e962c7575dd2e91fd388/bc10c/INSTAR_MQTT_Alarmserver_Homey_09.webp 460w", "/en/static/cbf22e5d4f43e962c7575dd2e91fd388/d00b9/INSTAR_MQTT_Alarmserver_Homey_09.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbf22e5d4f43e962c7575dd2e91fd388/81c8e/INSTAR_MQTT_Alarmserver_Homey_09.png 230w", "/en/static/cbf22e5d4f43e962c7575dd2e91fd388/08a84/INSTAR_MQTT_Alarmserver_Homey_09.png 460w", "/en/static/cbf22e5d4f43e962c7575dd2e91fd388/5a190/INSTAR_MQTT_Alarmserver_Homey_09.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cbf22e5d4f43e962c7575dd2e91fd388/5a190/INSTAR_MQTT_Alarmserver_Homey_09.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now click on `}<strong parentName="p">{`Test`}</strong>{` to test the animation and save your flow:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cf2bfe5e89850478a099a139f011b6fd/5a190/INSTAR_MQTT_Alarmserver_Homey_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABqUlEQVQoz5XNz2vTcBzG8a8eHAgi9MfajQ1xDsGTV48eRCei0B68iFvT0iEIW0U206S/hG2wg2vSZLWtGpNmTJtsf4L/hDevXjwIXrwMp7wlyYrT9eIXXjyf7+V5xFTd5MpGj6ubb7hQ3yal6CRkjaSsn/SsxXhgNZIKcqXFxKrJjNrhktpBTNYNJmoGl9c7zK51SFdaJMoayX+NKB81IGbW2kw/NzhXfsF5ZYuY2iSuaqGEcqSs/T0iD50cEBfXTdINnVhli3ilGWZMjYqj8ubI8pB8vFxjUjEQs5svmd4wiTeaJBt6aDxQP1LTSdZ0EtXgbpGqGaSqkXSg8sdUdRtx5rGMkJYQ+aUoA7ljpBJjxaecXVzhtPQEMb+MWCgh5kucCjyMiAfLjBUVxI32ayRnlwX7PXnXo+D6FNy9MCXHI2cPKLo+j3b2KQT/twOkIcsjb3nkrAGLts8d00V0P33gG5/h+wH8OICfh/DrMMr/fB+/fEVc61rcc99xs2sz96rPra7DXM/hdq/PXWuXbN8nM+R4ZBw/lHX2yAZp+2Rsn/v9fa63d/gN+XKwi0xxapgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf2bfe5e89850478a099a139f011b6fd/e4706/INSTAR_MQTT_Alarmserver_Homey_10.avif 230w", "/en/static/cf2bfe5e89850478a099a139f011b6fd/d1af7/INSTAR_MQTT_Alarmserver_Homey_10.avif 460w", "/en/static/cf2bfe5e89850478a099a139f011b6fd/c9875/INSTAR_MQTT_Alarmserver_Homey_10.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cf2bfe5e89850478a099a139f011b6fd/a0b58/INSTAR_MQTT_Alarmserver_Homey_10.webp 230w", "/en/static/cf2bfe5e89850478a099a139f011b6fd/bc10c/INSTAR_MQTT_Alarmserver_Homey_10.webp 460w", "/en/static/cf2bfe5e89850478a099a139f011b6fd/d00b9/INSTAR_MQTT_Alarmserver_Homey_10.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf2bfe5e89850478a099a139f011b6fd/81c8e/INSTAR_MQTT_Alarmserver_Homey_10.png 230w", "/en/static/cf2bfe5e89850478a099a139f011b6fd/08a84/INSTAR_MQTT_Alarmserver_Homey_10.png 460w", "/en/static/cf2bfe5e89850478a099a139f011b6fd/5a190/INSTAR_MQTT_Alarmserver_Homey_10.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cf2bfe5e89850478a099a139f011b6fd/5a190/INSTAR_MQTT_Alarmserver_Homey_10.png",
              "alt": "INSTAR MQTT Alarmserver for the Homey",
              "title": "INSTAR MQTT Alarmserver for the Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      